'use client';

import { init, FullStory } from '@fullstory/browser';
import { useEffect } from 'react';

import { useUserProfile } from '../../../context/userTokens';

import { iProviderProps } from '@/app/_providers/Providers';

const AppLayout = ({ children }: iProviderProps) => {
  const { profile } = useUserProfile();

  console.log(process.env.NEXT_PUBLIC_BACKEND_ROOT_DOMAIN);
  
  useEffect(() => {
    if (!profile) {
      return;
    }
    // Check if development or production/staging env
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
      try {
        init({ orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID });
        FullStory('setIdentity', {
          uid: profile.id,
          properties: {
            displayName: profile.name,
            email: profile.email,
          },
        });
      } catch (e) {
        console.error(e);
      }
    }else{
      console.error("Org id was not found.");
    }
  }, [profile]);

  return <>{children}</>
};
export default AppLayout;
